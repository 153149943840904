






























import { Vue, Component, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { fetchTransactions, getPlan, SecondDetail, starProduct } from '@/api/portfolio'
import { getRMBRate } from '@/api/common'
import { CommonMudule } from '@/store/modules/common'
import { comdifyKillNoZero } from '@/utils/filter'

import { countMoneyAmount } from './components/util'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { itemObj } from './components/defalutData'
import AsideMoney from './components/asideMoney.vue'
import TableWrap from './components/tableWrap.vue'
import NoAloneDrawer from './components/noAloneDrawer.vue'

@Component({
  components: {
    moduleHeader,
    AsideMoney,
    TableWrap,
    NoAloneDrawer
  }
})
export default class group2 extends Vue {
  // customerId
  customerId = CommonMudule.customerId;

  // 整体表格数据
  tableData = [];
  tableDataCopy = [];

  /**
   * aside 侧边(右侧贴边) 金额数据
   * 规则 如果用户在第一页没有选择定投，
   *    那么 计划定投金额、剩余定投金额 显示 '-'
   *      CurrentMoney 本次单笔金额
   *      LastMoney   剩余单笔金额
   *       planMoney   计划定投投资金额
   *      lastPlanMoney 剩余定投金额
   *      configOtherMoney 建议配置外币资产
   */
  alone = true
  noAloneNum = 0;
  asideData = {};
  asideMoneyCopy = {};
  starProductData = [];
  starProductFlag = false;
  //  侧边栏按钮状态
  btnStatus = false;

  // 非单独时 drawer状态以及信息
  drawer = false;
  drawerDetail = {};
  /**
   * created 加载 所有接口
   */
  created() {
    this.getAllRequest()
  }
  /**
   * getAllRequest
   * 接受所有请求
   *  table表格 add 侧边栏金额
   * fetchTransactions 表格
   * getPlan 侧边栏
   * getRMBRate 获取rmb汇率   美元/rmb
   */
  async getAllRequest() {
    const form = { customerId: this.customerId }
    const res = await Promise.all([
      fetchTransactions(form),
      getPlan(form),
      getRMBRate(),
      starProduct(form)
    ])
    this.getTableData(res[0])
    this.getAsideData(res[1], res[2])
    this.getStarProductData(res[3])
  }

  /**
   * getTableData
   * 整体表格返回数据
   */
  getTableData(data: any) {
    // 首次编辑时数据结构
    const _data = cloneDeep(data.data)
    let noAloneNum = 0
    _data.forEach((item: any) => {
      if (!this.alone) {
        noAloneNum += item.originAmount
      }
      item.subTypeProductActions.forEach((w: any) => {
        if (w.productActions.length === 0) {
          w.productActions.push(cloneDeep(itemObj))
        } else {
          w.productActions.forEach(this.productActionsFun)
        }
      })
    })
    this.noAloneNum = noAloneNum
    this.tableData = _data
    this.tableDataCopy = cloneDeep(data.data)
  }
  productActionsFun(v:any) {
    v.amountStr = comdifyKillNoZero(v.amount)
    v.investPlanAutoTermAmountStr = comdifyKillNoZero(v.investPlanAutoTermAmount)
  }
  /**
   * getAsideData
   * 侧边栏金额相关接口返回数据
   */
  getAsideData(_data: any, RMBRate: any) {
    const data = _data.data
    const RMBData = RMBRate.data
    // 建议配置外币资产
    const configOtherMoney = this.isConfigOther(data, RMBData)
    const { investmentAmount, investPlanAutoTermAmount } = data

    const form = {
      ...data,
      CurrentMoney: investmentAmount,
      LastMoney: investmentAmount,
      planMoney: investPlanAutoTermAmount,
      lastPlanMoney: investPlanAutoTermAmount,
      configOtherMoney,
    }
    this.asideData = form
    this.asideMoneyCopy = cloneDeep(form)
    this.$nextTick(() => {
      countMoneyAmount(this.tableData, this.asideData)
    })
  }

  /**
   * 非单独时点击 table title 展示抽屉
   * handleTitleClick
   * out table item
   */
  async handleTitleClick(out:any) {
    const parma = {
      customerId: CommonMudule.customerId,
      typeName: out.rootType
    }
    const { data } = await SecondDetail(parma)
    this.drawerDetail = data
    this.drawer = true
  }

  /**
 * 明星投资以及按组合
 */
  getStarProductData(data:any) {
    this.starProductData = data.data
  }
  /**
   * 点击明星一键配置
   * StarProduct
   */
  handleStarProduct() {
    this.starProductFlag = true
    const _data = cloneDeep(this.tableData)
    const _starProductData = cloneDeep(this.starProductData)
    _data.forEach((item:any) => {
      const _arr:any = []
      _starProductData.forEach((w:any) => {
        [w].forEach(this.productActionsFun)
        if (item.rootType === w.product.rootTypeName) {
          if (_arr.length === 0) {
            _arr.push({
              productActions: [w],
              subType: w.product.productTypeName,
              subTypeId: w.product.productTypeId
            })
          } else {
            _arr.forEach((v:any) => {
              if (v.subTypeId === w.product.productTypeId) {
                v.productActions.push(w)
              } else {
                _arr.push({
                  productActions: [w],
                  subType: w.product.productTypeName,
                  subTypeId: w.product.productTypeId
                })
              }
            })
          }
        }
      })
      if (_arr.length === 0) {
        item.subTypeProductActions[0].productActions = [cloneDeep(itemObj)]
      } else {
        item.subTypeProductActions = _arr
      }
    })
    this.tableData = _data
  }
  // 侧边栏切换
  handleBtn() {
    this.btnStatus = !this.btnStatus
  }

  /**
   * 注意带 is 前缀的都是辅助工具函数
   */

  /**
   * 外币配置
   *  isConfigOther
   */
  isConfigOther(data: any, RMBRate: number): number {
    const { investmentAmount, investPlanAutoTermAmount } = data
    const res =
      ((investmentAmount + investPlanAutoTermAmount) * 0.15) / RMBRate
    return this.isFixed(res)
  }
  // 保留小数, 如果小数最后一位是0 则省略
  isFixed = (res: number, num: number = 0): number => +res.toFixed(num);
}
