/**
 * 权重
 * rmbProportion 人民币比例
 * tableData table中数据
 * 规则 cell rmb 总数 / 所有 cell 金额总和
 */
const rmbProportion = (tableData: any, asideMoney:any) => {
  // 循环 计算总和
  let count = 0
  tableData.forEach((out: any) => {
    out.subTypeProductActions.forEach((_w: any) => {
      const productActions = _w.productActions
      const productActionsLth = productActions.length
      productActionsLth &&
        productActions.forEach((_v: any) => {
          count += +_v.approachCnyAmount
        })
    })
  })
  //  循环修改每个cell 权重
  tableData.forEach((out: any) => {
    out.subTypeProductActions.forEach((_w: any) => {
      const productActions = _w.productActions
      const productActionsLth = productActions.length
      productActionsLth &&
        productActions.forEach((_v: any) => {
          _v.percentage =
            +((_v.approachCnyAmount / count) * 100).toFixed(2) || 0
        })
    })
  })
  countMoneyAmount(tableData, asideMoney)
}

/**
 * 计算金额(侧边栏剩余、table item head)
 * countMoneyAmount
 * @param tab table 表格 all 数据
 * @param asideMoney  侧边栏金额
 */

const countMoneyAmount = (tab:any, asideMoney:any) => {
  /**
     * CurrentMoneyTotal 本次单笔投资金额
     * _LastMoneyTatol 剩余单笔金额
     * _lastPlanMoneyTotal 剩余定投金额
     */
  const { investPlanAutoTermAmount, investPlanAutoAmount, investmentAmount } = asideMoney
  let _CurrentMoney = investmentAmount
  let _LastMoney = investmentAmount
  let _lastPlanMoney = investPlanAutoTermAmount

  let count = investPlanAutoAmount + investmentAmount
  tab.forEach((out:any) => {
    count += +out.originAmount
    out.subTypeProductActions.forEach((_w:any) => {
      _w.productActions.forEach((_v:any) => {
        // p判断输入框是否有值
        const { approachCnyAmount, transactionType, product } = _v
        if (approachCnyAmount) {
          //  判断是否为卖出 卖出 + ，买入 -
          if (transactionType === 'Buy') {
            // 判断是否定投
            //   true 获取每期定投金额 _v.investPlanAutoTermAmount
            // 、 false 获取金额 ——v.approachCnyAmount
            if (product.enableAutomaticInvest) {
              const { investPlanAutoTermAmount, automaticInvest } = _v
              if (automaticInvest) {
                _lastPlanMoney -= +investPlanAutoTermAmount
              } else {
                _LastMoney -= +approachCnyAmount
              }
            } else {
              _LastMoney -= +approachCnyAmount
            }
          } else {
            _LastMoney += +approachCnyAmount
            _CurrentMoney += +approachCnyAmount
          }
        }
      })
    })
  })
  let a = 0
  tab.forEach((out:any) => {
    let _count = 0
    out.subTypeProductActions.forEach((_w:any) => {
      _w.productActions.forEach((_v:any) => {
        // p判断输入框是否有值
        const { approachCnyAmount, transactionType } = _v
        if (approachCnyAmount) {
          //  判断是否为卖出 卖出 + ，买入 -
          if (transactionType === 'Buy') {
            _count += +approachCnyAmount
          } else {
            _count -= +approachCnyAmount
          }
        }
      })
    })
    out.currentAmount = +(out.originAmount) + _count
    // out.originAmount  历史配置金额
    if (out.currentAmount !== 0 && _count !== 0) {
      out.currentPercentage = +(out.currentAmount / count * 100).toFixed(2)
    }
    a += out.currentAmount
  })

  const _asideMoney = {
    ...asideMoney,
    CurrentMoney: _CurrentMoney,
    LastMoney: _LastMoney,
    lastPlanMoney: _lastPlanMoney
  }
  for (const k in _asideMoney) {
    asideMoney[k] = _asideMoney[k]
  }
}
export { rmbProportion, countMoneyAmount }
