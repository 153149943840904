// 初始化table信息
const itemObj = {
  id: '',
  tenantId: '',
  createTime: '',
  createBy: '',
  createByName: '',
  deleted: false,
  investmentPlanId: '',
  productTypeId: '',
  transactionType: 'Buy',
  productId: '',
  productName: '',
  amount: '',
  amountStr: '', // 这个不是后台返回的， 主要为了显示金额blur 分位逗号字符串
  planEarnings: 0,
  percentage: 0,
  investPlanAutoTermAmount: '',
  investPlanAutoTermAmountStr: '', // 这个不是后台返回的， 主要为了显示每期定投金额blur 分位逗号字符串
  automaticInvest: false,
  approachCnyAmount: '',
  product: {
    id: '',
    tenantId: '',
    createTime: '',
    createBy: '',
    createByName: '',
    deleted: false,
    productName: '',
    productTypeId: '',
    productCurrency: 'CNY',
    currencyId: '',
    currencyName: '',
    productPeriod: '',
    productInfoLink: '',
    productExpectedReturn: 0,
    productSd: 0,
    productDrawdown: '',
    enableAutomaticInvest: false
  }
}
// 币种相关
const currencyArr = [
  {
    value: 'CNY',
    label: '人民币'
  }, {
    value: 'OTHERS',
    label: '外币'
  }
]

// 产品名
const productArr: any = []

// 定时投资
const fixedInvestArr = [{
  label: '是',
  value: true
}, {
  label: '否',
  value: false
}]

export {
  itemObj,
  currencyArr,
  productArr,
  fixedInvestArr
}
