






















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import {
  fetchProductList,
  changeRate,
  saveSecondPlan,
  deleteSecondPlan,
} from '@/api/portfolio'
import { PortMudule } from '@/store/modules/portfolio'
import {
  currencyArr,
  productArr,
  fixedInvestArr,
  itemObj,
} from './defalutData'
import { rmbProportion } from './util'
import { comdifyKillNoZero, removeComdifyKill } from '@/utils/filter'
import CurrentConfigTitle from './currentConfigTitle.vue'

@Component({
  components: {
    CurrentConfigTitle,
  },
  computed: {
    /**
     * btnStatus判断  W
     * classStatus
     */
    classStatus: () => (status: boolean): string =>
      status ? 'app-container _w pdr-85' : 'app-container _w',

    /**
     * 约合人民币
     * RateRmb
     *  row 行内容
     * tableData 表格arr
     * 规则 如果 币种为 rmb(productCurrency) 显示 -
     *    反之显示对应rmb汇率
     */
    RateRmb() {
      return (row: any, tableData: any) => {
        if (row.transactionType === 'Sell') {
          row.approachCnyAmount = row.amount
          this.$nextTick(() => {
            rmbProportion(tableData, (this as any).asideMoney)
          })
          return '-'
        }
        if (row.product.productCurrency === 'CNY') {
          row.approachCnyAmount = row.amount

          this.$nextTick(() => {
            rmbProportion(tableData, (this as any).asideMoney)
          })
          return '-'
        }

        if (row.amount) {
          // 判断 exchangeRate 字段是否存在
          //  true 时 是手动切换的 计算规则，当前输入内容* 汇率
          //  false 时 为后台数据返回状态 ， 直接返回后台计算好的汇率对应RMB的值
          if (row.product.exchangeRate) {
            this.$nextTick(() => {
              rmbProportion(tableData, (this as any).asideMoney)
            })
            // eslint-disable-next-line no-return-assign
            return (row.approachCnyAmount = +(
              row.amount * row.product.exchangeRate
            ).toFixed(2))
          }
          this.$nextTick(() => {
            rmbProportion(tableData, (this as any).asideMoney)
          })
          return +(row.approachCnyAmount || 0).toFixed(2)
        }
        return '0.00'
      }
    },

    /**
     * 辅助函数
     * isBuySell
     * 规则 买入true，卖出false
     */
    isBuySell: () => (type: string): boolean => type === 'Buy',

    /**
     * isAlone
     * 规则 单独 true 非单独 false
     */
    isAlone() {
      return (this as any).alone
    },
    /**
     * 交易类型
     * transactionType
     * 规则 买入显示，卖出隐藏
     */
    transactionType() {
      return (transactionType: string, content: any): string =>
        (this as any).isBuySell(transactionType) ? `${content}%` : '-'
    },

    /**
     * 固定投资disabled
     * isFixedInvestDisb
     *  */
    isFixedInvestDisb: () => (status: boolean): boolean => !status,

    /**
     * 每期定投金额
     *  规则
     *    1状态为买入 && 用户有输入金额  && 当前产品可以定投 true
     *      反之 false
     *    2 如果状态位卖出则显示’-‘
     * eachIssuFixedMoney
     */
    eachIssuFixedMoney() {
      return (row: any) => {
        const { product, automaticInvest, transactionType } = row
        return (this as any).isBuySell(transactionType) && automaticInvest
      }
    },

    /**
     * 产品说明
     * 规则 买入显示，卖出隐藏 true false
     * transactionType: () =>
     */
    productPlain: () => (productInfoLink: string): boolean => !!productInfoLink,

    /**
     * 操作按钮判断 add or remove  status true ： false
     * editIcon
     *  */
    editIcon: () => (idx: number, lgh: number): boolean => idx === lgh - 1,
  },
})
export default class TRableWrap extends Vue {
  /**
   * tableData 表格信息
   * asideMoney 侧边栏金额相关
   * btnStatus 侧边栏按钮状态
   * starProductFlag 明星选择状态
   */
  @Prop() tableData!: any[];
  @Prop() asideMoney!: any;
  @Prop() asideMoneyCopy!: any;
  @Prop() btnStatus!: boolean;
  @Prop() noAloneNum!: number;
  @Prop() starProductFlag !: boolean
  @Prop() tableDataCopy !:any[]

  /**
   * 规划类型
   * alone  true 单独 false 非单独
   */
  alone = true;
  /**
   * 币种
   * OTHERS CNY
   *  */
  currencyArr = cloneDeep(currencyArr);

  /**
   * 产品名
   */
  productArr = cloneDeep(productArr);

  /**
   * 是否定投
   * true false
   */
  fixedInvestArr = cloneDeep(fixedInvestArr);

  /**
   * 删除数据， 点击确定统一操作
   * deleteTable
   */
  deleteTable: any = [];
  /**
   * 点击头部标题
   * handleTitleClick
   * out item
   */
  handleTitleClick(out: any) {
    !this.alone && this.$emit('on-handle-title-click', out)
  }

  /**
   * 买入卖出
   * handleChangeBusiness
   * e event productActions 产品行信息  idx 行索引 row 行信息
   */
  handleChangeBusiness(e: any, productActions: any, idx: number, row:any) {
    const item = cloneDeep(itemObj)
    row.id && (this.deleteTable.push(row.id))
    e !== 'Buy' && (item.transactionType = e)
    productActions.splice(idx, 1, item)
  }

  /**
   * 卖出时触发
   */
  handleRowClass(row: any) {
    if (row.row.transactionType === 'Sell') {
      return 'sell'
    }
  }

  /**
   * change 选择币种
   * handleChangeCurrent
   * productAcon 当前表格信息
   * row, 当前行信息
   * idx 行 id
   * 规则，币种切换时，当前行重置输入信息
   */
  handleChangeCurrent(productAcon: any, row: any, idx: number) {
    // 重置当前行
    const item = cloneDeep(itemObj)
    row.id && (this.deleteTable.push(row.id))
    if (row.product.productCurrency === 'OTHERS') {
      item.product.productCurrency = 'CNY'
    } else {
      item.product.productCurrency = 'OTHERS'
    }
    productAcon.splice(idx, 1, item)
  }

  /**
   *   * focus 选择产品名
   * handleFocusProduct
   * productActions table当前表格信息
   * idx 行id
   *  productTypeId 行产品id
   *  row 行信息
   */
  async handleFocusProduct(
    productActions: any,
    idx: number,
    productTypeId: string,
    row: any
  ) {
    // 重置当前行(币种除外)
    const item: any = cloneDeep(itemObj)
    row.id && (this.deleteTable.push(row.id))
    item.product.productCurrency = row.product.productCurrency
    item.transactionType = row.transactionType
    productActions.splice(idx, 1, item)

    // ajax 请求
    const form = {
      productName: '',
      productTypeId,
      productCurrency: row.product.productCurrency,
      onSale: true,
      page: 0,
      size: 100,
    }
    this.productArr = []
    const { data } = await fetchProductList(form)
    this.productArr = data.content
  }

  /**
   *   * change 选择产品名
   * handleChangeProduct
   * row 行信息 prouctArr 产品名数组
   */
  handleChangeProduct(row: any, prouctArr: any) {
    const _find = prouctArr.find((item: any) => item.id === row.productName)
    row.product = _find
  }

  /**
    handleFocusMoney
    row 行信息
    */
  handleFocusMoney(row: any) {
    row.amountStr = removeComdifyKill(row.amountStr)
  }
  /**
   * change 输入金额
   * handleChangeMoney
   * row 行信息
   * 规则
   * 1 如果用户没有输入活选择产品名，提示，不进行文本输入文本内容
   *    反之 允许用户输入文本内容(type number)
   * 2 判断当前产品币种
   *    如果币种为 非 RMB 则请求汇率接口
   *    反之 直接显示 RMB（CNY）
   */
  async handleChangeMoney(row: any) {
    row.amount = row.amountStr
    const { productName, product, amount, transactionType } = row
    if (!productName) {
      if (transactionType !== 'Buy') {
        this.$message.info('请先输入产品名称')
      } else {
        this.$message.info('请先选择产品')
      }
      row.amount = row.amountStr = ''
      return
    }

    const { productCurrency, currencyId, exchangeRate } = product
    /**
     * productCurrency 币种名称
     * currencyId 币种id "currency-id-0001" === RMB
     */
    if (product && productCurrency !== 'CNY' && !exchangeRate) {
      const { data } = await changeRate(currencyId)
      row.product.exchangeRate = data
      row.approachCnyAmount = amount * data
    }
  }

  /**
   * blur 金额失焦
   * handleBlurMoney
   * row 行
   */
  handleBlurMoney(row: any) {
    row.amountStr = comdifyKillNoZero(row.amount)
  }

  /**
   * 是否定投
   * change handleChangeInvest
   * row 行内容
   */
  handleChangeInvest(row: any) {
    const { automaticInvest } = row
    row.amount = row.amountStr = row.approachCnyAmount = ''
    row.automaticInvest = !automaticInvest
  }

  /**
   * 每期定投金额
   * handleFocusEachIssu
   * row 行信息
   */

  handleFocusEachIssu(row: any) {
    row.investPlanAutoTermAmountStr = removeComdifyKill(
      row.investPlanAutoTermAmountStr
    )
  }
  /**
   * 每期定投金额
   * handleInputEachIssu
   * row 行信息 asideMoney 侧边金额相关
   */
  handleInputEachIssu(row: any, asideMoney: any) {
    const val = (row.investPlanAutoTermAmount =
      row.investPlanAutoTermAmountStr)
    const { durationYears, investPlanAutoFreq } = asideMoney

    /**
     * assumeInflationRate 通过膨胀率
     * durationYears 投资几年
     * investPlanAutoTermAmount 定投金额
     * investPlanAutoAmount 定投金额总和
     * investPlanAutoFreq
     *  Quarterly季度
     *  Monthly 月
     *  Annual 年
     *
     *
     */
    let count = 0
    if (investPlanAutoFreq === 'Quarterly') {
      count = val * 4 * durationYears
    } else if (investPlanAutoFreq === 'Monthly') {
      count = val * 12 * durationYears
    } else {
      count = val * 1 * durationYears
    }
    row.amount = row.approachCnyAmount = count
    row.amountStr = comdifyKillNoZero(row.amount)
  }

  /**
   * 每期定投金额
   * handleBlurEachIssu
   * row 行信息
   */

  handleBlurEachIssu(row: any) {
    row.investPlanAutoTermAmountStr = comdifyKillNoZero(
      row.investPlanAutoTermAmount
    )
  }

  /**
   * handleAddTable
   * 表格 add 按钮
   *
   */
  handleAddTable(tabItem: any) {
    tabItem.push(cloneDeep(itemObj))
  }

  /**
   * handleRemoveTable
   * 表格 删除 按钮
   *
   */
  handleRemoveTable(tabItem: any, idx: number, row: any) {
    tabItem.splice(idx, 1)
    row.id && this.deleteTable.push(row.id)
  }

  /**
   * handleSubmit
   * 提交
   */
  handleSubmit() {
    const { lastPlanMoney, LastMoney } = (this as any).asideMoney
    if (
      (!lastPlanMoney && +LastMoney.toFixed(0) !== 0) ||
      (lastPlanMoney &&
        +lastPlanMoney.toFixed(0) !== 0 &&
        +LastMoney.toFixed(0) !== 0)
    ) {
      this.$confirm(
        '您的投资产品信息与您当前的投资规划不符，请调整您的产品信息或投资计划',
        '提示',
        {
          confirmButtonText: '调整购买产品',
          cancelButtonText: '调整投资计划',
        }
      )
        .then(() => {})
        .catch(() => {
          this.$confirm(
            '返回调整投资计划将清除您现有产品信息，是否调整投资计划',
            '提示',
            {
              confirmButtonText: '调整投资计划',
              cancelButtonText: '取消',
            }
          ).then(() => {
            this.$router.push('/portfolio/group1')
          })
        })
    } else {
      this.submit()
    }
  }

  async submit() {
    const { tableData, tableDataCopy, deleteTable } = this

    // 如果用户点击一键配置， 则删除上次提交记录
    if (this.starProductFlag) {
      const __del: any = []
      tableDataCopy.forEach((out: any) => {
        out.subTypeProductActions.forEach((_w: any) => {
          _w.productActions.forEach((_v: any) => {
          // p判断输入框是否有值
            const {
              id,
              approachCnyAmount,
            } = _v
            if (approachCnyAmount) {
              if (id) {
                __del.push(id)
              }
            } else if (id) {
              __del.push(id)
            }
          })
        })
      })
      for (let i = 0; i < __del.length; i++) {
        await deleteSecondPlan({ id: __del[i] })
      }
    }
    const list: any = []
    const _del: any = []
    tableData.forEach((out: any) => {
      out.subTypeProductActions.forEach((_w: any) => {
        _w.productActions.forEach((_v: any) => {
          // p判断输入框是否有值
          const {
            amount,
            id,
            approachCnyAmount,
            transactionType,
            automaticInvest,
            investPlanAutoTermAmount,
            percentage,
            productName,
            product,
          } = _v
          if (approachCnyAmount) {
            const _product = cloneDeep(product) || {}
            const _obj = {
              ..._product,
              productId: _product.id,
              amount: +amount,
              approachCnyAmount: +approachCnyAmount,
              percentage,
              investPlanAutoTermAmount,
              transactionType,
              investmentPlanId: (this.asideMoney as any).id,
              automaticInvest,
            }
            if (transactionType === 'Sell') {
              _obj.productName = productName
              _obj.productTypeId = _w.subTypeId
            }
            delete _obj.id
            list.push(_obj)
            if (id) {
              _del.push(id)
            }
          } else if (id) {
            _del.push(id)
          }
        })
      })
    })

    // 点击确定 执行删除和保存操作
    const _delAllTable = [...new Set([..._del, ...deleteTable])]
    for (let i = 0; i < _delAllTable.length; i++) {
      await deleteSecondPlan({ id: _delAllTable[i] })
    }
    list.length && (await saveSecondPlan(list))
    this.$router.push('/portfolio/group3')
  }
}
