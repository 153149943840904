










































import { Vue, Component, Prop } from 'vue-property-decorator'
import { comdifyKillNoZero } from '@/utils/filter'
import { IsToFixed } from '@/utils/IsToFixed'
@Component({
  computed: {
    // 非单独单体投资计算
    noInestScale: () =>
      (item:any, noAloneNum:number, asideMoney:any):number =>
        comdifyKillNoZero(IsToFixed((item.suggestPercentage * (noAloneNum + asideMoney.CurrentMoney)) / 100, 1, 2) - item.originAmount),
    /**
     * 本次配置金额
     * currentConfigMoney
     * 规则
     *  如果 currentAmount(当前配置)金额为0， 则返回0(0%)
     *  反之显示对应数据
     *
     *  */
    currentConfigMoney: () => (
      currentAmount: number,
      currentPercentage: number
    ): string =>
      currentAmount > 0
        ? `${comdifyKillNoZero(+currentAmount.toFixed(2))}(${
          currentPercentage > 100 ? '100' : comdifyKillNoZero(+currentPercentage.toFixed(2))
        }%)`
        : '0 (0%)',
    // currentAmount > 0
    //   ? `(${
    //     currentPercentage > 100 ? '100' : comdifyKillNoZero(currentPercentage)
    //   }%)`
    //   : '0 (0%)',

    /**
     * 投资比例计算(单笔投资、定投)
     * investScale
     *
     */
    investScale: () => (
      suggestPercentage: number,
      investmentAmount: number
    ): number => +((suggestPercentage / 100) * investmentAmount).toFixed(2),
  },
})
export default class CurrentConfigTitle extends Vue {
  comdifyKillNoZero =comdifyKillNoZero
  /**
   * item table循环当前项
   * asideMoney 侧边栏金额
   */
  @Prop() item!: any;
  @Prop() asideMoney!: any;
  @Prop() asideMoneyCopy!: any;
  @Prop() alone !:boolean
  @Prop() noAloneNum !:number
}
