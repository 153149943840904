





























































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  computed: {
    /**
     * btnStatus判断  W
     * classStatus
     */
    classStatus() {
      return (status: boolean): String =>
        status ? 'aside-money-container r-105' : 'aside-money-container'
    },

    /**
     * 保留整数
     */
    _toFixed: () => (num: any) => +(num || 0).toFixed(0),
  },
})
export default class AsideMoney extends Vue {
  @Prop() asideMoney!: any;
  @Prop() btnStatus!: boolean;
}
