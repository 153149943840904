





































import { Vue, Component, Prop } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
@Component({
  components: {
    moduleHeader
  }
})
export default class NoAloneDrawer extends Vue {
    @Prop() drawer !: boolean
    @Prop() drawerDetail !: object
}
